import {
    Tag,
    Table,
    List,
} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import dayjs from "dayjs";

const DevicesList = () => {
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);

    const formatterTime = (val) => {
        return val ? dayjs(val).format('MM-DD HH:mm:ss') : ''
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '主机名',
            dataIndex: 'hostname',
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.hostname.length - b.hostname.length,
        },
        {
            title: 'IP',
            dataIndex: 'ipv4',
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.ipv4.length - b.ipv4.length,
        },
        {
            title: '状态',
            dataIndex: 'state',
            filters: [
                {
                    text: '在线',
                    value: 1,
                },
                {
                    text: '离线',
                    value: 2,
                },

            ],
            onFilter: (value, record) => record.state === value,
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => {
                let color="";
                let txt="未知";
                switch (record.state){
                    case 1:
                        color='green'
                        txt='在线'
                        break
                    case 2:
                        color='geekblue'
                        txt='离线'
                        break
                    default:
                        txt="未知";
                }
                return (
                    <Tag color={color} >{txt}</Tag>
                )
            },
            sorter: (a, b) => a.state - b.state,
        },
        {
            title: '在线时间',
            key: 'state_at',
            render: (_, record) => {
                let txt="";
                switch (record.state){
                    case 1:
                        txt=record.online_at
                        break
                    case 2:
                        txt=record.offline_at
                        break
                    default:
                        txt="未知"
                }
                return (
                    <span>{formatterTime(txt)}</span>
                )
            },
        },
        {
            title: '更新时间',
            dataIndex: 'update_at',
            sortDirections: ['ascend', 'descend'],
            render:formatterTime,
            sorter: (a, b) => a.update_at > b.update_at,
        },
    ];

    const getDateList = () => {
        AppAxios.get("/devices", {params: {}}).then(res => {
            setLoading(false);
            if (res.code === 200) {
                setTableData(res.data)
            }
        }).catch((e) => setLoading(false))
    }


    useEffect(() => {
        if (loading) {
            getDateList()
        }
    }, [loading]);


    return (
        <div>
            <h3>设备管理</h3>
            <Table columns={columns} dataSource={tableData} pagination={{defaultPageSize: 100}}/>

            <List
                itemLayout="horizontal"
                dataSource={tableData}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={
                                <span>{item.name ? item.name : item.hostname}{item.name ? '(' + item.hostname + ')' : ''}</span>}
                            description={item.ipv4 + "," + item.mac_address + ",在线时间:" + formatterTime(item.online_at)}
                        />
                        <div>{(() => {
                            switch (item.state) {
                                case 1:
                                    return <Tag color="#87d068">在线</Tag>
                                case 2:
                                    return <Tag color="#108ee9">离线</Tag>
                                default:
                                    return <Tag color="#2db7f5">未知</Tag>
                            }
                        })()}</div>
                    </List.Item>
                )}
            />

        </div>
    );
}

export default DevicesList
