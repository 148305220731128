
import {Card} from 'antd';
import React from 'react'

const Home = () =>{
    const links = [
        {"name":"Portainer", "url":"https://192.168.10.50:9443/"},
        {"name":"Jenkins", "url":"http://192.168.10.50:6002/"},
        {"name":"jellyfin", "url":"http://192.168.10.50:6003/"},
        {"name":"video", "url":"http://192.168.10.50:9999/"},
        {"name":"V2RayA", "url":"http://192.168.10.50:2017/"},
        {"name":"kibana", "url":"http://192.168.10.50:5061/"},
        {"name":"frpc-admin", "url":"http://192.168.10.60:7002/"},
        {"name":"AdGuard-Home", "url":"http://192.168.10.60:3000/"},
        // {"name":"Immich", "url":"http://192.168.10.50:2283/"},
        // {"name":"Android", "url":"http://192.168.10.50:8000/"},
        {"name":"BitWarden(内网)", "url":"http://192.168.10.50:6001/"},
        {"name":"NasGoApi", "url":"http://192.168.10.50:8030/"},
    ];
    const gridStyle = {
        width: '25%',
        textAlign: 'center',
    };
    return (
       <div>
           <h3>工作台</h3>
           <div>
               <Card title="网址导航" >
               {links.map((item, index) => {
                   return <Card.Grid key={index} style={gridStyle}>
                       <a rel="noopener noreferrer" target="_blank" href={item.url}>{item.name}</a>
                   </Card.Grid>
               })}
               </Card>
           </div>
       </div>
    )

}
export default Home
