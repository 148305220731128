import {ConfigProvider, Layout, Menu} from 'antd';
import React, {useState} from 'react';
import {Route, Routes, useNavigate,useLocation} from "react-router-dom";


import Home from "./backend/home";
import DevicesList from "./backend/devices";


//设置本地语言
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

const {Header, Content, Footer, Sider} = Layout;

const items = [
    {label: '工作台', key: 'home'},
    {label: '设备管理', key: 'devices'},
];


const BackendLayout = () => {
    const location = useLocation();
    const [current, setCurrent] = useState(location.pathname.substring(1));
    const [collapsed, setCollapsed] = useState(false);
    let navigate = useNavigate();
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    return (
        <ConfigProvider locale={zhCN}>
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="logo"/>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[current]}
                    onClick={onClick}
                    defaultSelectedKeys={['4']}
                    items={items}
                />
            </Sider>
            <Layout className="backend-layout-sider">
                <Header
                    className="site-layout-sub-header-background"
                    style={{padding: 0,}}/>
                <Content style={{margin: '24px 16px 0'}}>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 360,}}>

                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/home" element={<Home/>}/>
                            <Route path="/devices" element={<DevicesList/>}/>
                        </Routes>
                    </div>
                </Content>
                <Footer style={{textAlign: 'center',}}>
                    后台管理系统 © 2022-2023
                </Footer>
            </Layout>
        </Layout>
        </ConfigProvider>
    )
}

export default BackendLayout;
